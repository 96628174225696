import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Link from "gatsby-link";
import CTA from "./CTA";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Bookkeeping Services Vancouver
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus Bookkeeping offers the highest quality bookkeeping services
            in Vancouver for both individuals and small to medium sized
            businesses. We understand that it can be draining to manage your own
            finances. It is a time consuming task that will hinder your ability
            to operate your business at the highest capacity. This is why it is
            so important to hire a highly trained team to help you. That being
            said, you want to make sure that you hire a team that you can trust.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus is the top provider of Vancouver bookkeeping services. We are
            an experienced team that has been providing professional bookkeeping
            services for years. We always hold ourselves to the highest standard
            possible. This is why Certus is the team you can trust.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Bookkeeping Vancouver Services</h2>
          <p>
            Operating a business is a demanding pursuit that requires a lot of
            time and energy. Managing the finances on your own can prevent you
            from operating at maximum capacity. Certus Bookkeeping will help you
            achieve your goals by offering the highest quality professional
            bookkeeping. If you are a small business owner or individual that is
            in need of help with your finances Certus is the team for you.
          </p>
          <p>
            When you book a consultation with us, our team of financial experts
            will review your workflows as a means of understanding your specific
            needs. We will then create a plan that will save you time, money and
            ensure that you have everything you need to file your tax returns.
          </p>
        </div>
        <CTA />
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2> Get Started </h2>
          <p>
            If you would like to receive a quote please visit the{" "}
            <Link
              to="/contact-us"
              style={{
                textDecoration: "none",
                color: "#6B7280",
                "&:hover": {
                  background: "#efefef",
                },
                ":visited": {
                  color: "purple",
                },
              }}
            >
              contact us{" "}
            </Link>{" "}
            page on our website to schedule a consultation with one of our
            financial experts.
          </p>
        </div>
      </div>
    </div>
  );
}
