import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Bookkeeping Services Vancouver | Certus Bookkeeping</title>
      <meta
        name="description"
        content="We offer bookkeeping services in Vancouver. Connect with our professional staff of bookkeepers to get started today."
      />
      <link rel="canonical" href="https://certusbookkeeping.com/bookkeeping-services-vancouver" />
    </Helmet>
  );
}
