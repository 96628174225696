/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet";
const faqs = [
  {
    question:
      "Is it worth it to use a payroll service to pay only one employee?",
    answer:
      "Yes, when paying employees it is crucial that tax remittances are made to the CRA by the 15th of the following month. It is also imperative that T4’s are completed, filed with the CRA and submitted to the employee by the end of February of the following calendar year. Both of these duties will be carried out and ensured by your payroll provider.",
  },
  {
    question: "How do business owners solve bookkeeping troubles?",
    answer:
      "Business owners solve bookkeeping troubles by hiring a high end bookkeeping provider such as Certus Bookkeeping. A high end provider will ensure that your company has an efficient financial infrastructure and, in effect, reduce the workload. This will allow the owner to focus on growing the business in an effective, scalable manner while ensuring tax complicity.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": 
              [
                {
                  "@type": "Question",
                  "name": "Is it worth it to use a payroll service to pay only one employee?",
                  "acceptedAnswer": 
                    {
                      "@type": "Answer",
                      "text": "<p>Yes, when paying employees it is crucial that tax remittances are made to the CRA by the 15th of the following month. It is also imperative that T4’s are completed, filed with the CRA and submitted to the employee by the end of February of the following calendar year. Both of these duties will be carried out and ensured by your payroll provider.</p>"
                    }
                }, 
                {
                  "@type": "Question",
                  "name": "How do business owners solve bookkeeping troubles?",
                  "acceptedAnswer": 
                  {
                    "@type": "Answer",
                    "text": "<p>Business owners solve bookkeeping troubles by hiring a high end bookkeeping provider such as Certus Bookkeeping. A high end provider will ensure that your company has an efficient financial infrastructure and, in effect, reduce the workload. This will allow the owner to focus on growing the business in an effective, scalable manner while ensuring tax complicity.<p>"
                  }
                }
          ]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
